import root from "./root"

const HAS_ACCEPTED_ANALYTICS = 'has-accepted-analytics'
const GA_TRACKING_ID = process.env.GATSBY_GA_TRACKING_ID
const GA_TRACKING_COOKIE = `ga-disable`
const GLOBAL_GA_PROPERTY = `${GA_TRACKING_COOKIE}-${GA_TRACKING_ID}`

const hasAcceptedAnalytics = root.localStorage && root.localStorage.getItem(HAS_ACCEPTED_ANALYTICS);
root[GLOBAL_GA_PROPERTY] = !hasAcceptedAnalytics;

export default {
  GAOptIn : hasAccept => {
    root.localStorage && root.localStorage.setItem(HAS_ACCEPTED_ANALYTICS, hasAccept)
    root[GLOBAL_GA_PROPERTY] = !hasAccept
  }
}

export {
  GA_TRACKING_COOKIE
}
